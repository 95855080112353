interface RolePages {
	[role: string]: string[];
}

interface SubMenu {
	[pageId: string]: {
		id: string;
		text: string;
		path: string;
		icon: string;
	};
}

interface PageMenu {
	[pageId: string]: {
		id: string;
		text: string;
		path: string;
		icon: string;
		color: string;
		subMenu: SubMenu | null;
	};
}

// type UserRole = (string | null)[];

const getAppPagesAvailable = (
	userRoles: (string | null)[] | undefined,
	appPagesMenu: PageMenu,
	rolesPagesAllowed: RolePages,
) => {
	const allowedPages: PageMenu = {};

	(userRoles ?? [])
		.filter((role): role is string => role !== null)
		.forEach((role) => {
			rolesPagesAllowed[role]?.forEach((pageId) => {
				const page = appPagesMenu[pageId];
				if (page) {
					allowedPages[pageId] = { ...page, subMenu: null }; // Copy top-level page

					// Now, let's handle the submenus
					if (page.subMenu) {
						allowedPages[pageId].subMenu = Object.entries(page.subMenu)
							.filter(([subId]) => rolesPagesAllowed[role]?.includes(subId))
							.reduce<SubMenu>((acc, [subId, subPage]) => {
								acc[subId] = subPage; // Copy allowed subPage
								return acc;
							}, {});
					}
				}
			});
		});

	return allowedPages;
};

export default getAppPagesAvailable;
