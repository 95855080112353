import Swal from 'sweetalert2';

export async function confirmAction(
	confirmationMessage: string,
	service?: (...args: any[]) => Promise<any>,
	skipLoadingAndSuccessDisplay: boolean = false,
): Promise<any | null> {
	const confirmationResult = await Swal.fire({
		title: 'Confirmação',
		text: confirmationMessage,
		icon: 'question',
		showCancelButton: true,
		confirmButtonText: 'Sim',
		cancelButtonText: 'Não',
	});

	if (confirmationResult.isConfirmed) {
		try {
			Swal.fire({
				title: 'Processando...',
				text: 'Por favor, aguarde.',
				icon: 'info',
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
				didOpen: () => {
					Swal.showLoading();
				},
			});

			const result = service ? await service() : null;

			if (!skipLoadingAndSuccessDisplay) {
				await Swal.fire({
					title: 'Sucesso!',
					text: 'Ação Realizada Com Sucesso!',
					icon: 'success',
					confirmButtonText: 'Ok',
				});
			}

			return result;
		} catch (error: any) {
			// Default message
			let errorMessage = 'Ocorreu um erro desconhecido';

			// Check if this is an Axios error (i.e., it has a 'response' object)
			if (error.response) {
				errorMessage = error.response.data?.message || 'Erro de servidor desconhecido';
			} else if (error.message) {
				// Handle generic JavaScript errors (like new Error())
				errorMessage = error.message;
			}

			// Show the custom error message in Swal
			await Swal.fire({
				title: 'Erro!',
				text: `${errorMessage}`,
				icon: 'error',
				confirmButtonText: 'Ok',
			});

			return null; // Return null in case of an error
		}
	} else {
		// Se não for confirmado, apenas retorna null.
		return null;
	}
}
