import PropTypes from 'prop-types';
import { FC, ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../../../components/bootstrap/Alert';
import Button, { IButtonProps } from '../../../components/bootstrap/Button';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Popovers from '../../../components/bootstrap/Popovers';
import Spinner from '../../../components/bootstrap/Spinner';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import useDarkMode from '../../../hooks/useDarkMode';
import LANG, { ILang, getLangWithKey } from '../../../lang';
import { HeaderRight } from '../../../layout/Header/Header';
import CommonHeaderUser from './CommonHeaderUser';
import ApiException from '../../../services/api/ApiException';
import { ICheckList, ICheckListItem, ICheckListType } from '../../../types/accounts-type';
import CheckListService from '../../../services/api/customers/CheckListService';
import CheckListModal from './CheckListModal';
import AuthContext from '../../../contexts/authContext';
import { useLocation } from 'react-router-dom';
import facilpabx from '../../../assets/img/facilpabx.png';

interface ICommonHeaderRightProps {
	beforeChildren?: ReactNode;
	afterChildren?: ReactNode;
}
const CommonHeaderRight: FC<ICommonHeaderRightProps> = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const styledBtn: IButtonProps = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	const [checkListData, setCheckListData] = useState<ICheckListType>();
	const { t } = useTranslation(['notifications', 'checklist']);
	const [loading, setLoading] = useState(false);
	const { userData } = useContext(AuthContext);
	const location = useLocation(); // Get current route
	const userRoles = (userData?.roles?.map((role) => role.pivot.admin_role_id) ?? []).filter(
		(role): role is string => typeof role === 'string',
	);
	const roleMapping: Record<string, string[]> = {
		administrative: ['commercial', 'super_admin', 'financial', 'admin'],
		financial: ['commercial', 'super_admin', 'financial', 'admin'],
		technical: ['technical_support'],
	};

	const isTaskForUser = (taskSection: string) => {
		return roleMapping[taskSection]?.some((role) => userRoles.includes(role));
	};

	// Function to get the first uncompleted checklist item
	const getFirstIncompleteTask = (items: ICheckListItem[]) => {
		return items.find((item) => !item.completed);
	};
	// Check if any checklist has a pending task that is assigned to the user
	const hasPendingUserTasks = checkListData?.data.some((cl) => {
		if (cl.completed) return false;
		const firstTask = getFirstIncompleteTask(cl.items);
		return firstTask ? isTaskForUser(firstTask.section) : false;
	});

	useEffect(() => {
		setLoading(true);
		CheckListService.getAll().then((result) => {
			if (result instanceof ApiException) {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' color='danger' />
						<span>{t('notifications:server_error:error')}</span>
					</span>,
					'notifications:server_error:error_message',
				);
			} else {
				setCheckListData(result); // Update checklist data
			}
			setLoading(false);
		});
	}, [t, location.pathname]);

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const [selectedChecklist, setSelectedChecklist] = useState<ICheckList | null>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { i18n } = useTranslation();

	const changeLanguage = (lng: ILang['key']['lng']) => {
		i18n.changeLanguage(lng).then(() => {
			const langText = getLangWithKey(lng)?.text;
			const languageText = i18n.t('menu:languageChanged') + langText;
			const languageUpdatedText = i18n.t('menu:languageUpdatedMessage');
			showNotification(
				<span className='d-flex align-items-center'>
					<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
					<span>{languageText}</span>
				</span>,
				languageUpdatedText,
			);
		});
	};

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language.substring(0, 2));
	});

	const handleClickOffCanvas = () => {
		setOffcanvasStatus(true); // Open OffCanvas
		setLoading(true); // Set loading state

		CheckListService.getAll().then((result) => {
			if (result instanceof ApiException) {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Error' size='lg' className='me-1' color='danger' />
						<span>{t('notifications:server_error:error')}</span>
					</span>,
					'notifications:server_error:error_message',
				);
			} else {
				setCheckListData(result); // Update checklist data
			}
			setLoading(false);
		});
	};

	return (
		<HeaderRight>
			<div className='row g-3'>
				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'
							aria-label='Toggle dark mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/* Lang Selector */}
				<div className='col-auto'>
					<Dropdown>
						<DropdownToggle hasIcon={false}>
							{typeof getLangWithKey(i18n.language as ILang['key']['lng'])?.icon ===
							'undefined' ? (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									className='btn-only-icon'
									aria-label='Change language'
									data-tour='lang-selector'>
									<Spinner isSmall inButton='onlyIcon' isGrow />
								</Button>
							) : (
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={
										getLangWithKey(i18n.language as ILang['key']['lng'])?.icon
									}
									aria-label='Change language'
									data-tour='lang-selector'
								/>
							)}
						</DropdownToggle>
						<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
							{Object.keys(LANG).map((i) => (
								<DropdownItem key={LANG[i].lng}>
									<Button
										icon={LANG[i].icon}
										onClick={() => changeLanguage(LANG[i].lng)}>
										{LANG[i].text}
									</Button>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>

				{/*	Notifications */}
				<div className='col-auto position-relative'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Checklist'
						color={hasPendingUserTasks ? 'danger' : 'light'}
						onClick={handleClickOffCanvas} // Call the function
						aria-label='Notifications'
					/>

					{/* Alert Icon (Only shows when there are pending tasks) */}
					{hasPendingUserTasks && (
						<span className='position-absolute top-10 start-80 translate-middle badge rounded-pill bg-danger'>
							<Icon icon='PriorityHigh' color='light' size='sm' />
						</span>
					)}
				</div>

				{afterChildren}

				<div className='col-auto'>
					<CommonHeaderUser />
				</div>
			</div>

			<OffCanvas
				id='checklistCanvas'
				titleId='checklistOverviewLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}
				isBackdrop={false}
				isBodyScroll={true}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='checklistOverviewLabel'>
						<Icon icon='Checklist' className='me-2' /> {t('checklist:overview')}
					</OffCanvasTitle>
				</OffCanvasHeader>

				<OffCanvasBody>
					{loading ? (
						<p>{t('checklist:loading')}</p>
					) : checkListData ? (
						<>
							{/* UNCOMPLETED CHECKLISTS */}
							{/* <h5 className='mt-3'>
								<Icon icon='PendingActions' className='me-2' />{' '}
								
							</h5> */}
							<h5
								className='mt-4 d-flex align-items-center'
								style={{ gap: '8px' }} // Ensures spacing consistency
							>
								<Icon icon='PendingActions' color='warning' size='lg' />
								<span>{t('checklist:pending_title')}</span>
							</h5>
							<div className='mb-4'>
								{checkListData.data.filter((cl) => !cl.completed).length > 0 ? (
									checkListData.data
										.filter((cl) => !cl.completed)
										.map((checklist) => {
											const firstTask = getFirstIncompleteTask(
												checklist.items,
											);
											return (
												<Alert
													key={checklist.id}
													isLight
													color='warning'
													className='d-flex align-items-start p-3'
													onClick={() => {
														setSelectedChecklist(checklist);
														setIsModalOpen(true);
													}}
													style={{
														cursor: 'pointer',
														display: 'flex',
														flexWrap: 'nowrap',
														gap: '10px',
														alignItems: 'center',
													}}>
													{/* ✅ Service Type Image/Icon with Label */}
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															alignItems: 'center',
														}}>
														{checklist.service_id === 'pabx' && (
															<>
																<img
																	src={facilpabx}
																	alt='FacilPABX'
																	style={{
																		width: '40px',
																		height: '40px',
																	}}
																	className='rounded-circle'
																/>
															</>
														)}
														{checklist.service_id === 'nun' && (
															<>
																<Icon
																	icon='Tty'
																	color='info'
																	size='3x'
																/>
															</>
														)}
														{checklist.service_id === 'sainte' && (
															<>
																<Icon
																	icon='Phone Forwarded'
																	color='navy'
																	size='3x'
																/>
															</>
														)}

														{/* ✅ Checklist Type Label */}
														<span
															style={{
																fontSize: '0.85rem',
																fontWeight: 'bold',
																color:
																	checklist.type === 'activation'
																		? '#17a2b8' // Info (Blue)
																		: checklist.type ===
																		  'alteration'
																		? '#d4a017' // Warning (Yellow)
																		: checklist.type ===
																		  'cancellation'
																		? '#dc3545' // Danger (Red)
																		: '#555', // Default (Gray)
																marginTop: '4px',
																textTransform: 'capitalize',
															}}>
															{checklist.type === 'cancellation'
																? 'Cancelar'
																: t(
																		`checklist:types.${checklist.type}`,
																  )}
														</span>
													</div>

													{/* ✅ Text Section */}
													<div
														style={{
															display: 'flex',
															flexDirection: 'column',
															width: '100%',
														}}>
														{/* ✅ Company Name */}
														<div className='d-flex align-items-center'>
															<strong>
																{checklist.company_name.length > 20
																	? checklist.company_name.slice(
																			0,
																			20,
																	  ) + '...'
																	: checklist.company_name}
															</strong>
														</div>

														{/* ✅ Task Details */}
														{firstTask ? (
															<div className='mt-1'>
																<span className='d-flex align-items-center'>
																	<Icon
																		icon='AssignmentLate'
																		className='me-1'
																	/>
																	<strong>
																		&nbsp;
																		{firstTask.task_name
																			.length > 25
																			? firstTask.task_name.slice(
																					0,
																					25,
																			  ) + '...'
																			: firstTask.task_name}
																	</strong>
																</span>
																<span className='d-flex align-items-center'>
																	<Icon
																		icon='Person'
																		className='me-1'
																	/>
																	<strong>
																		&nbsp;
																		{firstTask.section.toUpperCase()}
																	</strong>
																</span>
															</div>
														) : (
															<div className='mt-1'>
																{t(
																	'checklist:alerts:no_tasks_found',
																)}
															</div>
														)}
													</div>
												</Alert>
											);
										})
								) : (
									<p>{t('checklist:no_pending')}</p>
								)}
							</div>
							<br></br>
							{/* COMPLETED CHECKLISTS */}
							<h5
								className='mt-4 d-flex align-items-center'
								style={{ gap: '8px' }} // Ensures spacing consistency
							>
								<Icon icon='CheckCircle' color='success' size='lg' />
								<span>{t('checklist:completed_title')}</span>
							</h5>

							{checkListData.data.filter((cl) => cl.completed).length > 0 ? (
								checkListData.data
									.filter((cl) => cl.completed)
									.map((checklist) => (
										<div key={checklist.id}>
											{/* ✅ Service Type Icon */}

											{/* ✅ Alert with Checklist Type & Company Name */}
											<Alert
												// icon='TaskAlt'
												isLight
												color='success'
												className='d-flex align-items-center p-3'
												style={{
													display: 'flex',
													flexWrap: 'nowrap',
													gap: '10px',
													alignItems: 'center',
												}}>
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														alignItems: 'center',
													}}>
													{checklist.service_id === 'pabx' && (
														<>
															<img
																src={facilpabx}
																alt='FacilPABX'
																style={{
																	width: '40px',
																	height: '40px',
																}}
																className='rounded-circle'
															/>
														</>
													)}
													{checklist.service_id === 'nun' && (
														<>
															<Icon
																icon='Tty'
																color='info'
																size='3x'
															/>
														</>
													)}
													{checklist.service_id === 'sainte' && (
														<>
															<Icon
																icon='Phone Forwarded'
																color='navy'
																size='3x'
															/>
														</>
													)}

													{/* ✅ Checklist Type Label */}
													<span
														style={{
															fontSize: '0.85rem',
															fontWeight: 'bold',
															color:
																checklist.type === 'activation'
																	? '#17a2b8' // Info (Blue)
																	: checklist.type ===
																	  'alteration'
																	? '#d4a017' // Warning (Yellow)
																	: checklist.type ===
																	  'cancellation'
																	? '#dc3545' // Danger (Red)
																	: '#555', // Default (Gray)
															marginTop: '4px',
															textTransform: 'capitalize',
														}}>
														{checklist.type === 'cancellation'
															? 'Cancelar'
															: t(
																	`checklist:types.${checklist.type}`,
															  )}
													</span>
												</div>
												<div
													style={{
														display: 'flex',
														justifyContent: 'center', // ✅ Centers Horizontally
														alignItems: 'center', // ✅ Centers Vertically
														textAlign: 'center', // ✅ Centers Text
														width: '50%', // ✅ Ensures Proper Alignment
													}}>
													<strong>
														{checklist.company_name.length > 25
															? checklist.company_name.slice(0, 25) +
															  '...'
															: checklist.company_name}
													</strong>
												</div>
											</Alert>
										</div>
									))
							) : (
								<p>{t('checklist:no_completed')}</p>
							)}

							{/* Checklist Modal */}
							<CheckListModal
								isOpen={isModalOpen}
								setOffcanvasStatus={setOffcanvasStatus}
								setIsOpen={setIsModalOpen}
								checklist={selectedChecklist || { id: '', items: [] }}
								handleRefresh={handleClickOffCanvas}
							/>
						</>
					) : (
						<p>{t('checklist:no_found')}</p>
					)}
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
