import axios from 'axios';
import { IUserAdmins } from '../../types/user-type';
import ApiException from './ApiException';

const postadminLogout = async (): Promise<Array<IUserAdmins> | ApiException> => {
	const csrf = localStorage.getItem('csrf');

	try {
		// Include the CSRF token as a request parameter
		const { data } = await axios.post(
			`${process.env.REACT_APP_LARAVEL_URL}/logout`,
			`_token=${csrf}`,
			{
				withCredentials: true,
			},
		);
		localStorage.clear();
		window.location.href = `${process.env.REACT_APP_LARAVEL_URL}/login`;
		return data.data;
	} catch (error: any) {
		return new ApiException(error.response.data.message);
	}
};

const postChangePassword = async (formData: any): Promise<Array<IUserAdmins> | ApiException> => {
	try {
		const payload = new URLSearchParams();
		const csrf = localStorage.getItem('csrf') || 'defaultCsrfValue';
		payload.append('current_password', formData.current_password);
		payload.append('new_password', formData.new_password);
		payload.append('new_password_confirmation', formData.new_password_confirmation);
		payload.append('_token', csrf);

		const { data } = await axios.post(
			`${process.env.REACT_APP_LARAVEL_URL}/change-password`,
			payload,
			{
				withCredentials: true,
			},
		);

		return data.data;
	} catch (error: any) {
		return new ApiException(error.response.data.message);
	}
};

const ApiWeb = {
	postadminLogout,
	postChangePassword,
};

export default ApiWeb;
