import axios from 'axios';

const Api = () => {
	const jwtToken = localStorage.getItem('jwtToken');
	return axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
		headers: {
			Authorization: `Bearer ${jwtToken}`,
		},
	});
};

export default Api;
