import PropTypes from 'prop-types';
import { FC } from 'react';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Icon from '../../../components/icon/Icon';
import Badge from '../../../components/bootstrap/Badge';
import { ICheckListItem } from '../../../types/accounts-type';
import Input from '../../../components/bootstrap/forms/Input';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { confirmAction } from '../../../helpers/confirmAction';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Button from '../../../components/bootstrap/Button';
import CheckListService from '../../../services/api/customers/CheckListService';
import { useNavigate } from 'react-router-dom';

interface ICheckListModalProps {
	isOpen: boolean;
	setIsOpen: (open: boolean) => void;
	setOffcanvasStatus: (open: boolean) => void;
	checklist: any;
	handleRefresh: () => void;
}

const CheckListModal: FC<ICheckListModalProps> = ({
	isOpen,
	setIsOpen,
	checklist,
	handleRefresh,
	setOffcanvasStatus,
}) => {
	const { t } = useTranslation(['general', 'company-service', 'validation', 'checklist']);
	const navigate = useNavigate();
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			checklist_id: checklist?.id || '',
			items:
				checklist?.items?.map((task: ICheckListItem) => ({
					id: task.id,
					task_name: task.task_name,
					section: task.section,
					completed: task.completed,
					responsable: task.completed_by?.name || null, // ✅ Store completed_by.name
					response: checklist?.service_data?.[task.task_name] || '', // Pre-fill response if completed
				})) || [],
		},
		onSubmit: async (values) => {
			// Compare current values with initial values to get only changed items
			const changedItems = values.items.filter((task: ICheckListItem, index: number) => {
				const initialTask = formik.initialValues.items[index];
				return (
					initialTask &&
					(task.completed !== initialTask.completed ||
						task.response !== initialTask.response)
				);
			});

			if (changedItems.length > 0) {
				const updatedValues: {
					checklist_id: number;
					items: { id: number; completed: boolean; response?: string }[];
				} = {
					checklist_id: values.checklist_id,
					items: changedItems.map((task: ICheckListItem) => ({
						id: task.id,
						completed: task.completed,
						response: task.response ?? '', // Ensure response is always defined
					})),
				};

				await confirmAction(t('checklist:message:confirm_update'), async () => {
					await CheckListService.putUpdateCheckList(
						updatedValues.checklist_id,
						updatedValues.items, // Pass only `items`, since your function expects it separately
					);
				});

				formik.resetForm();
				setIsOpen(false);
				handleRefresh(); // ✅ Trigger data refresh
			}
		},
	});
	if (!isOpen || !checklist) return null; // Ensure modal renders only when open and checklist exists
	if (isOpen) {
		return (
			<Modal isOpen={isOpen} setIsOpen={setIsOpen} size='lg' isStaticBackdrop={false}>
				<ModalHeader
					setIsOpen={(open: boolean) => {
						// ✅ Fixed here too
						setIsOpen(open);
						if (!open) {
							formik.resetForm();
						}
					}}>
					<ModalTitle id={'checklist-modal'}>
						<Icon icon='Assignment' className='me-2' />
						<Badge
							className='cursor-pointer h5'
							color='info'
							rounded='default'
							onClick={() => {
								navigate(`../customers/${checklist.account_id}`);
								setIsOpen(false);
								// setOffcanvasStatus(false);
							}}>
							{checklist.company_name}
						</Badge>{' '}
						-{' '}
						<span
							style={{
								fontWeight: 'bold',
								textTransform: 'capitalize',
								color:
									checklist.type === 'activation'
										? '#17a2b8' // Info (Blue)
										: checklist.type === 'alteration'
										? '#d4a017' // Warning (Yellow)
										: checklist.type === 'cancellation'
										? '#dc3545' // Danger (Red)
										: '#555', // Default (Gray)
							}}>
							{t(`checklist:types.${checklist.type}`)}
						</span>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<h5>
						<Icon icon='PendingActions' className='me-2' />
						{t('checklist:modal:tasks_overview')}
					</h5>

					{/* TASK LIST (Both Completed & Pending) */}
					{formik.values.items.length > 0 ? (
						formik.values.items.map((task: ICheckListItem, index: number) => (
							<div
								key={task.id}
								className='d-flex align-items-center mb-2 p-2 rounded'
								style={{
									backgroundColor: task.completed ? '#d4edda' : '#fff3cd', // Green for completed, Yellow for pending
									borderLeft: `5px solid ${
										task.completed ? '#28a745' : '#ffc107'
									}`, // Adds left color bar
								}}>
								<Icon
									icon={task.completed ? 'TaskAlt' : 'ErrorOutline'}
									className={`me-2 ${
										task.completed ? 'text-success' : 'text-warning'
									}`}
								/>
								<span className='me-2'>{task.task_name}</span>

								{/* Show Input for Uncompleted Tasks */}
								{!task.completed ? (
									task.task_name.trim().endsWith('?') ? (
										// ✅ Yes/No Checkbox
										<ChecksGroup className='ms-2'>
											<Checks
												type='checkbox'
												id={`items[${index}].response_yes`}
												label={t('general:yes')}
												name={`items[${index}].response`}
												value='sim'
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>,
												) => {
													const isChecked = e.target.checked;
													formik.setFieldValue(
														`items[${index}].response`,
														isChecked ? 'sim' : '',
													);
													formik.setFieldValue(
														`items[${index}].completed`,
														isChecked,
													); // ✅ Set completed
													formik.setFieldValue(
														`items[${index}].responsable`,
														'✅',
													);
												}}
												checked={
													formik.values.items[index]?.response === 'sim'
												}
												isInline
											/>
										</ChecksGroup>
									) : (
										// ✅ Standard Text Input
										<Input
											autoComplete='off'
											type='text'
											placeholder={
												t('checklist:fields:response_placeholder') as string
											}
											className='form-control ms-2'
											style={{ width: '200px' }}
											name={`items[${index}].response`}
											value={formik.values.items[index]?.response || ''}
											onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
												const value = e.target.value;
												formik.setFieldValue(
													`items[${index}].response`,
													value,
												);
												formik.setFieldValue(
													`items[${index}].responsable`,
													'✅',
												);
											}}
											onBlur={() => {
												const value =
													formik.values.items[index]?.response?.trim();
												if (value && value.length > 0) {
													formik.setFieldValue(
														`items[${index}].completed`,
														true,
													); // ✅ Mark as completed when user leaves the input
												}
											}}
										/>
									)
								) : (
									// ✅ Completed Task: Show Answer & Undo Button
									<div className='d-flex align-items-center ms-2'>
										{formik.values.items[index]?.response &&
										formik.values.items[index]?.response.trim() !== '' ? (
											<Badge
												color='info'
												className='me-2'
												style={{
													whiteSpace: 'normal',
													wordWrap: 'break-word',
													overflowWrap: 'break-word',
													fontSize: '0.75 rem', // Smaller font
													padding: '0.5rem 0.5rem', // Reduce padding
													maxWidth: '450px', // Adjust width for better wrapping
													display: 'inline-block', // Ensures proper text wrapping
													textAlign: 'left',
												}}>
												{formik.values.items[index]?.response}
											</Badge>
										) : null}

										{/* 🔄 Undo Button */}
										<Button
											className='ms-2'
											color='danger'
											size='sm'
											onClick={() => {
												formik.setFieldValue(
													`items[${index}].completed`,
													false,
												);
												formik.setFieldValue(
													`items[${index}].response`,
													'',
												); // Reset response
											}}>
											<Icon icon='Undo' className='me-1' />
											{t('checklist:buttons:undo')}
										</Button>
									</div>
								)}

								<Badge
									className='ms-auto'
									color={task.completed ? 'success' : 'warning'}>
									{task.completed && formik.values.items[index]?.responsable
										? formik.values.items[index]?.responsable
										: task.section
										? task.section.toUpperCase()
										: 'UNKNOWN'}
								</Badge>
							</div>
						))
					) : (
						<p>{t('checklist:alerts:no_tasks_found')}</p>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						className='mt-3 border-light'
						color={
							formik.values.items.every((task: ICheckListItem) => task.completed)
								? 'success'
								: 'primary'
						}
						isDisable={!formik.dirty || formik.isSubmitting}
						onClick={() => {
							formik.handleSubmit();
						}}
						icon={
							formik.values.items.every((task: ICheckListItem) => task.completed)
								? 'check_circle'
								: 'save'
						}>
						{formik.values.items.every((task: ICheckListItem) => task.completed)
							? t('checklist:buttons:finalize')
							: t('checklist:buttons:save')}
					</Button>
				</ModalFooter>
			</Modal>
		);
	}
	return null;
};

CheckListModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	checklist: PropTypes.object.isRequired,
};

export default CheckListModal;
