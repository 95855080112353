import { ICheckList, ICheckListType } from '../../../types/accounts-type';
import Api from '../ApiConfig';
import ApiException from '../ApiException';

const getAll = async (): Promise<ICheckListType | ApiException> => {
	try {
		const { data } = await Api().get('/checklists');

		const parsedData = data.data.map((checklist: ICheckList) => ({
			...checklist,
			service_data:
				typeof checklist.service_data === 'string'
					? JSON.parse(checklist.service_data)
					: checklist.service_data,
		}));

		// Return the structured response
		return { ...data, data: parsedData };
	} catch (error: any) {
		throw new ApiException(error.response?.data?.message || 'Unknown error occurred');
	}
};

const putUpdateCheckList = async (
	checklistId: number | string,
	updatedItems: { id: number; completed: boolean; response?: string }[],
): Promise<any | ApiException> => {
	try {
		// Ensure the request body is correctly formatted
		const payload = {
			checklist_id: checklistId,
			items: updatedItems.map((item) => ({
				id: item.id,
				completed: item.completed,
				...(item.response ? { response: item.response } : {}), // Only include response if it's present
			})),
		};
		const response = await Api().put(`/checklists/${checklistId}`, payload, {
			headers: {
				'Content-Type': 'application/json', // Ensure proper JSON format
			},
		});

		return response.data;
	} catch (error: any) {
		throw new ApiException(error.response?.data?.message || 'Error updating checklist');
	}
};

const postCreateChecklist = async (
	company_service_identifier: string | null,
	type: string | null,
): Promise<any | ApiException> => {
	try {
		const createCheckList = await Api().post(`/checklists/`, {
			company_service_identifier,
			type, // ✅ Send `type` as a request payload
		});
		return createCheckList;
	} catch (error: any) {
		throw new ApiException(error.response?.data?.message || 'An error occurred');
	}
};

const CheckListService = {
	getAll,
	putUpdateCheckList,
	postCreateChecklist,
};

export default CheckListService;
